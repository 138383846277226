import { useEffect, useState } from "react";
import Progressbar from "../Components/Progressbar/Progressbar";
import { useAppSelector } from "../store/store";

export default function AppLayout () {

  const patientObj:any = useAppSelector(state => state.patient.patientObj[0]);
  
  const [payment, setPayment] = useState([]);
  
  console.log(patientObj.paymentType);

  useEffect(() => {
    setPayment(patientObj.paymentType);
    console.log("app layout", payment);
  }, [patientObj])

  


    return(
        <>
          <Progressbar />
        </>
    )
}