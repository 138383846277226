import { Container } from "react-bootstrap";
import "./id.css"
import { useTranslation } from "react-i18next";

export default function IdInstructions() {

    const {t, i18n} = useTranslation();
    const lng = navigator.language;

    return (
        <>
            <Container className="id-container">
                <div className="step-wrap">
                <div className={`step-item completed`}>
                <div className="step-count">1</div>
                <div className="text-cont"><p className="p1">{t('patientIdInstruction1')}</p></div>
                </div>
    
                <div className={`step-item completed`}>
                <div className="step-count">2</div>
                <div className="text-cont"><p>{t('patientIdInstruction2')}</p></div>
                </div>
    
                <div className={`step-item completed`}>
                <div className="step-count">3</div>
                <div className="text-cont"><p>{t('patientIdInstruction3')}</p></div>
                </div>
    
                <div className={`step-item completed`}>
                <div className="step-count">4</div>
                <div className="text-cont"><p>{t('patientIdInstruction4')}</p></div>
                </div>
    
                <div className={`step-item completed`}>
                <div className="step-count">5</div>
                <div className="text-cont"><p>{t('patientIdInstruction5')}</p></div>
                </div>
                </div>
            </Container>
        </>
    )
}