

export const englishLang = {
    authPage: {
        title: "Verify your email address",
        linkInstructionsParagraph: "A link to sign in will be sent to your email",
        emailPlaceHolder: "Email Address",
        spanMsg1: "Email link sent…",
        spanMsg2: "Verify my email",
        alertMsgParagraph: "Thanks for confirming your email address, check for an email from noreply@simplifiedsystemprocess.com to sign in using this email address.",
        alertMsgNoRefresh: "Please do not refresh the page or click more than once."
    },

    authPageSms: {
        title: "Verify your phone number",
        instructions: "You will receive an SMS message with 6 digit one time passcode code to sign in.",
        phoneLabel: "Phone Number",
        otpTitle: "Enter a verification code",
        phonePlaceHolder: "Phone Number",
        spanMsgPhone1: "Verification code sent...",
        spanMsgPhone2: "Get Verification code",
        otpPlaceHolder: "Enter code",
        signInButton: "Sign In",
        alertMsgParagraphPhone: "Thanks for confirming your phone number.  You will receive a text message with a one time passcode to sign in to simplifiedsystemprocess.",
        alertMsgNoRefreshPhone: "Please do not refresh the page or click more than once."
    },
    
    patientInfoPage: {
        title: "Patient Information",
        formLabelFname: "First Name",
        formLabelLastName: "Last Name",
        formLabelAddress: "Address",
        formLabelCity: "City",
        formLabelState: "State",
        formLabelZip: "Zip",
        formLabelPhone: "Phone",
        formLabelEmail: "Email",
        formLabelPaymentType: "Payment Type",
        dropDownOptionDeposit: "Deposit",
        dropDownOptionPayment: "Partial Payment",
        radioLabelTitle: "Who’s paying",
        radioLabelOptionPatient: "Patient",
        radioLabelOptionPayor: "Payor",
        formLabelAmount: "Amount",
        formFeedBackMsg: "Amount entered must be between $250 and $1000",
        formFeedBackMsgPartialPayment: "Amount entered must be between $500 and $20,000",
        button: "AGREE"
    },
    
    patientId: {
        title: "Patient Identification Documents",
        instructionsTitle: "How to take a correct picture of your ID",
        button: "Next",
        instruction1: "Don’t upload the same document twice. You must upload a separate    document for each.",
        instruction2: "Use only legible, valid and current documents. Make sure the document isn’t damaged or illegible.",
        instruction3: "Use only unexpired documents.",
        instruction4: "Prevent image errors. When taking a digital photo of your document, be sure that all four corners are visible, and that there is no glare or other obstruction.",
        instruction5: "Submit both the front and back of documents when prompted. Some documents (like driver’s licenses) have important information on the back.  Submit an image of the back of the document whenever prompted.",
    },

    payorId: {
        title: "Payor Identification Documents",
        instructionsTitle: "How to take a correct picture of your ID",
        button: "Next",
    },
    
    patientInvoice: {
        title: "Contract Details",
        button: "Next"
    },
    
    patientContract: {
        title: "Patient Contract",
        button: "Next"
    },
    
    paymentAuthorization: {
        title: "Payment Authorization",
        payDate: "Payment Date",
        formLabelName: "Name which appears on Credit Card*",
        formLabelPayorEmail: "Payor Email*",
        formLabelCardNumber: "Credit Card Number*",
        formLabelExpDate: "Exp Date*",
        formFeedBackDate: "Enter date",
        formLabelCvc: "CVC/CVV*",
        formFeedBackCvc: "Enter security code",
        formLabelZip: "Billing Zip*",
        formLabelAddress: "Billing Address*",
        formLabelCity: "City*",
        formLabelState: "State*",
        paragraph1: "I",
        paragraph2: "authorize DOLLS PLASTIC SURGERY to withdraw money from my account for the amount mentioned on this form.  This payment will payment will go towards the balance of",
        paragraph3: "and I am aware that this deposit is",
        paragraph4: "NON-REFUNDABLE",
        paragraph5: ". I am aware that I MUST be PRESENT and bring the card being used above the day of Pre-Op.  If not the surgeon has the right to cancel my surgery.",
        formLabelAgreement: "I agree to the terms",
        button: "PAY NOW",
        encryptionMsg: "Your credit card information is encrypted",
        declineHeader: "We were unable to process your payment",
        unableToAuthenticateMsg: "Transaction could not authenticate.  Please use another form of payment.",
        expirationValidationMain: "Proper expiration date format is MMYY",
        validationNoSpecialCharacters: "Numbers only allowed",
        expirationValidationMonth: "Enter a valid expiration month",
        expirationValidationYear: "Enter a valid expiration year",
        expirationValidationDate: "Enter a valid expiration date"
    },
    
    confirmation: {
        Header: "Your payment was successful",
        transId: "Transaction ID:",
        confirmationMsg: "Thanks for Booking with Dolls Plastic Surgery",
        emailMsg: "Check your email for your receipt"
    },
    
    
    Error401: {
        title: "401 Not Authorized"
    },
    
    Error401LinkExpired: {
        title: "The link has expired. Please contact your sales coordinator for a new link."
    },
    
    Error401LinkCancelled: {
        title: "The link has been cancelled. Please contact your sales coordinator for a new link."
    },
    
    Error401StepsComplete: {
        title: "Process has been completed and Payment has been made.  No further action required.  Please contact your sales coordinator for any additional questions."
    },
    
    Error403: {
        title: "403 Forbidden"
    },
    
    Error404: { 
        title: "404 Not Found"
    },
    
    errorPayment: {
        title: "Error processing payment. Please contact your sales coordinator for more information"
    },
    modalComponent: {
        titleDecline: "Card Declined",
        body1Decline: "Unable to process payment.",
        body2Decline: "Verify the information you entered is correct."
    }
}