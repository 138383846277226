import { Dispatch, SetStateAction, createContext, useState } from "react"

export type User = {
    id: string;
}

export interface UserContextInterface {
    userLinkId: User,
    setUserLinkId: Dispatch<SetStateAction<User>>
}

const defaultState = {
    userLinkId: {
        id: ''
    },
    setUserLinkId: (userLinkId: User) => {}
} as UserContextInterface

export const UserContext = createContext(defaultState)

type UserProviderProps = {
    children: React.ReactNode
}


export default function UserProvider({children}: UserProviderProps) {
    const [userLinkId, setUserLinkId] = useState<User>({
        id: ''
    })

    return (
        <UserContext.Provider value={{userLinkId, setUserLinkId}}>
            {children}
        </UserContext.Provider>
    )

}