import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";

const cookies = new Cookies;

export interface Link {
  redirectLink: string;  
  linkId: string;
}

interface LinkState {
  linkObj: Link;
}

const initialState: LinkState = {
  linkObj: {
   redirectLink: "",
   linkId: ""
  },
};

export const LinkSlice = createSlice({
  name: "link",
  initialState,
  reducers: {
    updateLink: (state, action: PayloadAction<{ redirect_link: string }>) => {
      const { redirect_link } = action.payload;
      const updatedLink: any = state.linkObj as Link;
      updatedLink.redirect_link = redirect_link;
      console.log("LINK FROM INSIDE REDUX ENTIRE LINK", updatedLink.redirect_link);
    },
    updateLinkId: (state, action: PayloadAction<{ link_id: string }>) => {
        const { link_id } = action.payload;
        const updatedLinkId: any = state.linkObj as Link;
        updatedLinkId.link_id = link_id;
        console.log("INSIDE REDUX ID", updatedLinkId.link_id)
        if(!cookies.get('link_id')) {
          cookies.set('link_id', updatedLinkId.link_id);
          console.log("link id set inside redux call", updatedLinkId.link_id)
        }
        
    },
    resetLink: (state) => {
      return {linkObj: {
        redirectLink: "",
        linkId: ""
      }}    
    }
  },
});

export default LinkSlice.reducer;
export const { updateLink, updateLinkId, resetLink } = LinkSlice.actions;
