import { Button, Modal } from "react-bootstrap";
import './modal.css'
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function ModalComponent({handleClose, show, message}: any) {

    console.log("show inside modal", show);
    console.log("Inside modal message", message);

    const {t, i18n} = useTranslation();
    const lng = navigator.language;

    //CVV Response Codes
    const cvvResponseCodes:any = {
        "N": "CVV2/CVC2 No match",
        "P": "Not processed",
        "S": "Issuer indicates that the CVV2/CVC2 data should be present on the card, but the merchant has indicated that the CVV2/CVC2 data is not resent on the card",
        "U": "Issuer has not certified for CVV2/CVC2 or Issuer has not provided Visa with the CVV2/CVC2 encryption keys"
    }

    //AVS Response Codes
    const avsResponseCodes:any = {
        "A": "Address matches - ZIP Code does not match",
        "B": "Street address match, Postal code in wrong format",
        "C": "Street address and postal code in wrong formats",
        "D": "Street address and postal code match",
        "E": "AVS Error",
        "F": "Address does compare and five-digit ZIP code does compare",
        "G": "Service not supported by non-US issuer",
        "I": "Address information not verified by international issuer",
        "M": "Street Address and Postal code match",
        "N": "No Match on Address (Street) or ZIP",
        "O": "No Response sent",
        "P": "Postal codes match, Street address not verified due to incompatible formats.",
        "R": "Retry, System unavailable or Timed out",
        "S": "Service not supported by issuer.",
        "U": "Address information is unavailable"
    }

    const cvvArray = ['M'];
    const avsArray = ['W', 'X', 'Z', 'Y'];


    let messageResult:any = "";
    if(message.ssl_result_message) {
        //check if cvv match
       if(!cvvArray.includes(message.ssl_cvv2_response)) {
            console.log("CVV doesn't match");
            messageResult = cvvResponseCodes[message.ssl_cvv2_response] 
       }
       //check if avs zip code match
       else if(!avsArray.includes(message.ssl_avs_response)) {
            console.log("Zip code doesn't match");
            messageResult = avsResponseCodes[message.ssl_avs_response]
       }
       else {
        //decline is not due to incorrect cvv or zip code
            console.log("Decline due to something other than incorrect cvv or zip code");
            messageResult = message.ssl_result_message;
       }
        
       
       
    }


    return (
        <>
            <Modal centered size="sm" show={show}>
                <Modal.Title className="modal-title"><strong>{t('modalTitleDeclineMsg')}</strong></Modal.Title>
                <Modal.Body className="modal-body">{t('modalBody1DeclineMsg')} {t('modalBody2DeclineMsg')}</Modal.Body>
                <div className="modal-message-div"><strong>{message && message.errorMessage ? message.errorMessage : messageResult ? messageResult : message}</strong></div>
                <Modal.Footer className="modal-footer">
                    <Button variant="dark" className="modal-btn" onClick={handleClose}>OK</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}