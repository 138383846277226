// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyD6oeEjkUp88SwiFdi_Sah6_gDYG5oNwNA",
  authDomain: "simplifed-system.firebaseapp.com",
  projectId: "simplifed-system",
  storageBucket: "simplifed-system.appspot.com",
  messagingSenderId: "651111158816",
  appId: "1:651111158816:web:e9bf8513985e15463f9845",
  measurementId: "G-5RZF9VCSP0"
};

const firebaseConfigProd = {
  apiKey: "AIzaSyAiL88cquy_1w2WeRfjAosHuE5ok3NCJx8",
  authDomain: "simplified-system-prod.firebaseapp.com",
  projectId: "simplified-system-prod",
  storageBucket: "simplified-system-prod.appspot.com",
  messagingSenderId: "572913363095",
  appId: "1:572913363095:web:f2ea504c6cdd02fe6a21d2",
  measurementId: "G-HQQN62NXP7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfigProd);
export const auth = getAuth(app);
export default app;

