import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { StepSlice } from "./features/stepSlice";
import { PatientSlice } from "./features/patientSlice";
import { PaymentSlice } from "./features/paymentSlice";
import { configureStore } from "@reduxjs/toolkit";
import { StepProductionSlice } from "./features/stepSliceProduction";
import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { ContinueSlice } from "./features/continueSteps";
import CryptoJS from "crypto-js";
import { StorageKeyProp } from "../Types/storageProp";
import { AuthSlice } from "./features/authSlice";
import { LinkSlice } from "./features/linkSlice";



let credentials: StorageKeyProp = {
    key: process.env.REACT_APP_STORAGE_KEY + ""
}

const encryptionKey = CryptoJS.enc.Utf8.parse(credentials.key);

//Encrypt Data
const encryptData = (data: any) => {
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), encryptionKey, {
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        iv: CryptoJS.enc.Base64.parse('16')
    });
    return encryptedData.toString();
}

//Decrypt Data
const decryptData = (encryptedData: any) => {
    const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, encryptionKey, {
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        iv: CryptoJS.enc.Base64.parse('16'),
    });

    const decryptedData = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8))
    return decryptedData;
}

//Persist Configuration
const persistConfig:any = {
    key: 'root',
    version: 1,
    storage,
    serialize: (data: any) => {
        const encryptedData: any = encryptData(data);
        return JSON.stringify({encryptedData})
    },
    deserialize: (serializedData: any) => {
        try {
            const { encryptedData } = JSON.parse(serializedData);
            if(encryptedData) {
                return decryptData(encryptedData);
            }
        } catch (error) {
            console.log(error);
        }
        return undefined;
    }
}

const reducer = combineReducers({
    stepProduction: StepProductionSlice.reducer,
    patient: PatientSlice.reducer,
    payment: PaymentSlice.reducer,
    step: StepSlice.reducer,
    continue: ContinueSlice.reducer,
    auth: AuthSlice.reducer,
    link: LinkSlice.reducer
})


const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
      serializableCheck: false,
    })
})

export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<ReturnType<typeof store.getState>> = useSelector;
