

// *******REDIRECT AUTH URL********************
const devRedirectAuthUrl = "https://neuronah2o.com"
const prodRedirectAuthUrl = "https://simplifiedsystemprocess.com"

export const baseAuthUrl = prodRedirectAuthUrl


//*******ELAVON CHECKOUT.JS SCRIPT*****************
const devElavonCheckoutScript = "https://api.demo.convergepay.com/hosted-payments"
const prodElavonCheckoutScript = "https://api.convergepay.com/hosted-payments"

export const baseUrlElavonCheckoutScript = prodElavonCheckoutScript


//*******ELAVON 3D Secure SCRIPT*****************
const devElavon3dSecureScript = "https://uat.libs.fraud.eu.elavonaws.com/sdk-web-js/1.2.0/3ds2-web-sdk.min.js"
const prodElavon3dSecureScript = "https://libs.fraud.elavongateway.com/sdk-web-js/1.0.5/3ds2-web-sdk.min.js"

export const base3dSecureElavonScript = prodElavon3dSecureScript


//*******ELAVON CONVERGE 3D Secure Elavon SDK*****************
const devElavon3dSdkUrl = "https://uat.gw.fraud.eu.elavonaws.com/3ds2"
const prodElavon3dSdkUrl = "https://gw.fraud.elavongateway.com/3ds2"

export const base3dSdkUrl = prodElavon3dSdkUrl


// *********BACKEND API URL****************
const devBackEndApi = "https://billingapi-image-l3srlynxwa-ue.a.run.app"
const prodBackEndApi = "https://dpsprocessapi-b7um6vtdra-ue.a.run.app"

export const billingApiUrl = prodBackEndApi