import { createSlice } from "@reduxjs/toolkit";

export interface Patient {
  FirstName: string;
  LastName: string;
  Address: string;
  City: string;
  State: string;
  Zip: string;
  Phone: string;
  Email: string;
  paymentSource: string;
  paymentType: string;
  paymentAmount: number;
  isPaymentLinkLocked: boolean;

}

interface PatientState {
  patientObj: Patient[];
}

const initialState: PatientState = {
  patientObj: [],
};

export const PatientSlice = createSlice({
  name: "patient",
  initialState,
  reducers: {
    addPatient: (state, action) => {
      state.patientObj.push(action.payload);
    },
    removePatient: (state) => {
      return {patientObj: []}
    }
  },
});

export default PatientSlice.reducer;
export const { addPatient, removePatient } = PatientSlice.actions;
