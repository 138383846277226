import { url } from "inspector";
import { Card, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function Error404() {

    const {t, i18n} = useTranslation();
    const lng = navigator.language;

    //document.body.style.backgroundColor = "#551A8B";

    return(
        <>
        <div>
        <Container className="error-display-container">
            <Card className="error-card">
                <div className="error-box">
                    <h3>{t('error404Title')}</h3>
                </div>
            </Card>
        </Container>
        </div>
        </>
    )
}