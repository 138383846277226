import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface Step {
  title: string;
  routePath: string;
  order: number;
  isComplete: boolean;
}

interface StepState {
  stepsObj: Step[];
}

const initialState: StepState = {
  stepsObj: [],
};

export const StepSlice = createSlice({
  name: "step",
  initialState,
  reducers: {
    addSteps: (state, action) => {
      state.stepsObj.push(action.payload);
      
    },
    updateStep: (state, action: PayloadAction<{ order: number; isComplete: boolean }>) => {
      const { order, isComplete } = action.payload;
      const updatedStep: any = state.stepsObj[0] as Step;
      for (let i = 1; i < updatedStep.steps.length; i++) {
        if (updatedStep.steps[i].order === order) {
          updatedStep.steps[i].isComplete = isComplete;
        }
      }
    },
  },
});

export default StepSlice.reducer;
export const { updateStep, addSteps } = StepSlice.actions;
