import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface Payment {
  ssl_txn_id: string;
  ssl_approval_code: string;
  ssl_last_name: string;
  ssl_result_message: string;
  ssl_token_response: string;
  ssl_transaction_type: string;
  ssl_amount: string;
}

interface PaymentState {
  paymentObj: Payment;
}

const initialState: PaymentState = {
  paymentObj: {
    ssl_txn_id: "",
    ssl_approval_code: "",
    ssl_last_name: "",
    ssl_result_message: "",
    ssl_token_response: "",
    ssl_transaction_type: "",
    ssl_amount: ""
  },
};

export const PaymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    updateTransId: (state, action: PayloadAction<{ ssl_txn_id: string }>) => {
      const { ssl_txn_id } = action.payload;
      const updatedTransId: any = state.paymentObj as Payment;
      updatedTransId.ssl_txn_id = ssl_txn_id;
    },
    updatePayment: (state, action: PayloadAction<{ ssl_amount: string }>) => {
      const { ssl_amount } = action.payload;
      const updatedPayment: any = state.paymentObj as Payment;
      updatedPayment.ssl_amount = ssl_amount;
    },
    resetPaymentObj: (state) => {
      return {paymentObj: {
        ssl_txn_id: "",
        ssl_approval_code: "",
        ssl_last_name: "",
        ssl_result_message: "",
        ssl_token_response: "",
        ssl_transaction_type: "",
        ssl_amount: ""
      }}    
    }
  },
});

export default PaymentSlice.reducer;
export const { updateTransId, updatePayment, resetPaymentObj } = PaymentSlice.actions;
